<template>
    <div>
    <v-card>
        <div class="date p-2">
            <strong>SESSION MATIN : </strong>
            <b style="color:white" class="pauseLine">Infectiologie</b>
        </div>
        <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#3F8EBC" class="pauseLine">1ÈRE THÉMATIQUE : DIAGNOSTIC ET TRAITEMENT DES INFECTIONS EN RÉANIMATION</b><br>
                    <b style="color:#D25C56">Modérateurs :</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Khatouf Mohammed -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Nassik Hicham -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Bentalha Aziza</b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">08h30-08h55</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">L’antibiothérapie en réanimation : Quand débuter et quand arrêter ?</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/harrar.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr El Harrar Rachid, Casablanca </span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">08H55-09H20</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Pneumonies nosocomiales : Les nouveautés dans la prise en charge.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/brahim_ahmadi.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr EL Ahmadi Brahim, Rabat</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">09H20-09H45</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Les infections associées aux abords vasculaires en réanimation : les dés
                            sont-ils jetés ? 
                        </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/aissaoui.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Aissaoui Younes, Marrakech</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#D25C56">Modérateurs :</b>
                <b class="ml-1" style="color:#D25C56"> Pr Houari Nawfal -</b>
                <b class="ml-1" style="color:#D25C56"> Pr Hachimi Abdelhamid-</b>
                <b class="ml-1" style="color:#D25C56"> Pr El Koraichi Alae</b>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">09H45-10H35</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : L’intérêt des PCR multiplex dans la prise en charge des infections en réanimation.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/yahyaoui.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Yahyaoui Ghita, Fès<b style="color:#97CB94">Point de vue du biologiste</b> </span>
                        </a><br><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/dendane.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Dendane Tarik, Rabat <b style="color:#97CB94">Point de vue du réanimateur </b> </span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="120" src="../../assets/logos/aliance.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">10H35-11H00</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : L’azithromycine injectable : indications et modalités de prescription. </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/abidi.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Abidi Khalid Rabat</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="100" src="../../assets/logos/amanys.jpg" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">11H00-11h20</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon><br>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> Visite des stands et e-posters  </b>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">2ÈME THÉMATIQUE : LES INFECTIONS À GERMES MULTIRÉSISTANTS: NOUVEAU DÉFI EN RÉANIMATION</b><br>
                <b style="color:#D25C56">Modérateurs :</b>
                <b class="ml-1" style="color:#D25C56"> Pr Barrou Lahoucine -</b>
                <b class="ml-1" style="color:#D25C56"> Pr Samkaoui abdenasser - </b>
                <b class="ml-1" style="color:#D25C56"> Pr Doumiri Mouhssine</b>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">11H20-11H45</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Epidémiologie et mécanismes des infections à bactéries multirésistantes en réanimation</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/mahmoud.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Mahmoud Mustapha, Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">11h45-12h10</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Stratégie d’épargne des carbapénèmes: intérêt et modalités pratiques</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/bouazzaoui.jpeg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr El Bouazzaoui Abderrahim, Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">12h10-12h35</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">L’Acinetobacter en réanimation et stratégies d’antibiotiques : challenges et réalités.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/ezzouine.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Ezzouine Hanane, Casablanca</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">12h35-13H00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Les entérobactéries productrices de Bétalactamases à spectre élargie (BLSE) : actualités de la prise en charge.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/kechna.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Kechna Hicham, Meknès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#D25C56">Modérateurs :</b>
                <b class="ml-1" style="color:#D25C56"> Pr Bouketta Brahim -</b>
                <b class="ml-1" style="color:#D25C56"> Dr Chaieri Ahlam - </b>
                <b class="ml-1" style="color:#D25C56"> Pr Amor Mourad</b>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">13h00-13h25</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">     
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium: La place de l’anidulafungine dans la prise en charge des candidoses invasives.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Bouketta Brahim, Fès</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="100" src="../../assets/logos/phizer.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">13h25-13h50</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : Intérêt de ceftazidime-avibactam dans le traitement des bactéries gram négatif résistantes aux carbapénèmes.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/derkaoui.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Derkaoui Ali, Fès</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="100" src="../../assets/logos/phizer.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">14H00-15H00</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon><br>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> Visite des stands et e-posters  </b>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <div class="date p-2">
            <strong>SESSION APRÈS-MIDI : </strong>
            <b style="color:white" class="pauseLine">Hémodynamique
                Les états de choc dans tous leurs états
            </b>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#D25C56">Modérateurs :</b>
                <b class="ml-1" style="color:#D25C56"> Pr Bensouda Adil -</b>
                <b class="ml-1" style="color:#D25C56"> Pr Hosni Brahim - </b>
                <b class="ml-1" style="color:#D25C56"> Pr Tadili Jawad</b>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h00-15h25</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12"  align="left" >
                        <span class="sessionTitre ">Place de la pressionartérielle invasive dans le monitorage des états de choc</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/hamzaoui.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Hamzaoui Olfa ,Reims</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h25-15h50</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Monitorage du débit cardiaque dans les états de choc : obligation ou luxe ?</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/monnet.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Monnet Xavier ,(Paris)</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h50-16h15</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Svo2, Gap CO2 : intérêt dans la prise en charge des états de choc</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/teboul.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Teboul Jean-Louis ,(Paris)</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
            <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#D25C56">Modérateurs :</b>
                    <b class="ml-1" style="color:#D25C56"> Pr El Kettani Chafik -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Sbai Hicham - </b>
                    <b class="ml-1" style="color:#D25C56"> Pr Ziadi Amra</b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">16h15-16h40</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : Levosimendan :indication et perspectives thérapeutiques.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/Levy.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Levy Bruno, Nancy </span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="120" src="../../assets/logos/afric.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">16h40-17h05</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : la thrombophylaxie en périopératoire.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/nsiri.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Nsiri Afak, Casablanca</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="120" src="../../assets/logos/afric.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">17h05-17h30</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon><br>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> Visite des stands et e-posters  </b>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
            <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#D25C56">Modérateurs :</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Faroudy Mamoun -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Mouaffak Youssef - </b>
                    <b class="ml-1" style="color:#D25C56"> Pr Laoutid Jaouad</b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">17h30-17h55</b>
                    </v-col>
                </v-row>
                <v-row class="pr-2">
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : la prise en charge nutritionnelle des états de choc</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/touzani.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Touzani Soumaya, Fès</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="100" src="../../assets/logos/afric.png" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#D25C56">Modérateurs :</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Kanjaa Nabil -</b>
                    <b class="ml-1" style="color:#D25C56"> Dr Kohen Jamaleddine - </b>
                    <b class="ml-1" style="color:#D25C56"> Pr El Hijri Ahmed</b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">17h55-18h20</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"  align="left" >
                        <span class="sessionTitre ">Quand et comment je prescris un remplissage vasculaire dans le choc septique.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/monnet.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Monnet Xavier, (Paris)</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">18h20-18h45</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"  align="left" >
                        <span class="sessionTitre ">L’usage pratique des catécholamines dans le choc septique. </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/teboul.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Teboul Jean-Louis, (Paris)</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">19h00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"  align="left" >
                        <span class="sessionTitre ">Hommage et mot de clôture</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
    </v-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            isImageLoaded: false,
            panel: 0
           
        }
    },
    props: ['slideshow'],
    components: {
    },
    methods: {
      hideHand(){
        this.animation = false
      },
      onImageLoad () {
        this.isImageLoaded = true
        }
    },

    mounted() {
      
    
  },
}
</script>
<style>
@media all and (max-width: 768px) {

  .descriplist{
    font-size: .7em;
 }
}
</style>