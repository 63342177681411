<template>
    <v-container>
        <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#77A662"  >
                    Mot du président
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col cols="12">
                <v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
                    <v-row>
                        <v-col cols="12" md="2" sm="12"  class="person  text-center p-md-5 p-sm-3">
                                <img src="/speakers/adnane.jpg" alt="Image" class="img-fluidmb-5 sp1Img" style="width: 150px; height: 150px;">
                        </v-col>
                        <v-col  cols="12" md="10" sm="12" class="text-left pa-10 text">
                            Chères consœurs, chers confrères<br>
                            En tant que président de l'association des médecins anesthésistes réanimateurs de Fés (AMARF), j'ai l'immense plaisir de vous annoncer l'organisation du 7ème congrès de l’AMARF et ce le 29, 30 septembre et le 1er octobre 2022, à l'hôtel Marriott à Fès.<br>
                            Plusieurs thématiques sont retenues : l'hémodynamique des états de choc, les infections nosocomiales en réanimation, l'anesthésie en obstétrique, la simulation en anesthésie-réanimation, l'anesthésie locorégionale périphérique.<br>
                            Au cours de ce congrès, 2 journées de clubs de la SMAAR seront organisées : la 1ère journée SIRI (Club Simulation en Santé, Réalité Virtuelle et Intelligence Artificielle en Anesthésie-Réanimation) et la 9ème journée CARPO (Club des Anesthésistes Réanimateurs pratiquant l’Obstétrique)<br>
                            Sans oublier les communications orales et e-posters qui pourront être soumis en cliquant le lien (soumettre ici) dans le fichier pdf de l'affiche du congrès.<br>
                            Bienvenue au congrès de l'AMARF.<br>
                            Bienvenue à Fès.
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      more:false
    }
  },
}
</script>
<style >
.propo{
    text-align: center;
}

@media all and (max-width: 768px) {
  
  .propsHead{
    font-size: 1.5rem;
    padding: .75rem;
    color:#EC5713
  }
}
</style>