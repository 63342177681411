<template>
    <div class="body">
        <div class="text-center d-md-none mt-4 mb-5">
			<v-btn rounded color="red darken-3 mt-3" dark>
				<a href="https://amarf2022i.ascreacongres.ma/" target="_blank"  style="color:white!important">
					<b>SOUMISSION DES ABSTRACTS</b>
				</a>
			</v-btn>
		</div>
        <v-container grid-list-xs>
        <template>
                <div>
                <flip-countdown deadline="2022-09-29 08:00:00"></flip-countdown>
                </div>
            </template>
        </v-container>
    </div>
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown'
export default {
  components: { FlipCountdown },
    methods: {
    goTo(path){
           this.$router.push(path)
        },
},
    
}
</script>
<style>
    /* general styling */


</style>