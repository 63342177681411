<template>
    <div>
        <div class="social" style="text-align:right">
            <v-card class="social flex pa-1" max-width="40px" color="white">
                <a href="https://web.facebook.com/congresAMARF/" target="_blank"><img height="29" width="29" class="img-responsive mb-1" src="../assets/F.png" ></a> 
                <a href="https://www.youtube.com/channel/UCdM7YZ-Bn-ZloWDWkXoJPyA" target="_blank"> <img height="29" width="29" class="img-responsive " src="../assets/Y.png" ></a> 
				<a href="https://www.linkedin.com/company/congr%C3%A8s-amarf/" target="_blank"> <img height="29" width="31" class="img-responsive " src="../assets/l.png" ></a> 
            </v-card>
        </div>
        <v-img src="https://drive.google.com/uc?id=1T2ZX885xAT2DeME3m6B8NLtPj1-8OS2F&export=download" class="footpara" aspect-ratio="4.5">
		<div class="footparashad" id="contact">
			<v-row >
				<v-col cols="12" md="4" sm="12"  align="center" class="mt-5 ">
					<a href="#" ><img  class="img-responsive Logofoter" src="../assets/logo.png" height="100" width="280" ></a>
				</v-col>
				<v-col cols="12" md="8" sm="12" class="mt-sm-15 infofooter">
					<h2><b style="color:#E4A837">SECRÉTARIAT DU CONGRÈS</b></h2>
					<b style="color:#E4A837">POUR PLUS D'INFORMATIONS SUR L'INSCRIPTION</b><br>
					<span style="color:black">Mme Nouhaila Ibnoughafir :</span> <a href="tel:+212660442963" class="contactAdn" style="text-decoration:none;">+212 660 44 29 63</a>
					<span style="color:black"> - E-mail :</span> <a href="mailto:nouhaila.ibnoughafir@hashtagsante.ma" class="contactAdn" style="text-decoration:none;">nouhaila.ibnoughafir@hashtagsante.ma</a><br>
					<span style="color:black">Mr Khalil Sibari :</span> <a href="tel:+212602820075" class="contactAdn" style="text-decoration:none;">+212 602 82 00 75</a>
					<span style="color:black"> - E-mail :</span> <a href="mailto:khalil.sibari@hashtagsante.ma" class="contactAdn" style="text-decoration:none;">khalil.sibari@hashtagsante.ma</a><br>
				</v-col>
			</v-row>
		</div>
	</v-img>
    </div>
</template>

<script>
export default {

  data: () => ({
  }),
  methods: {
    
},
mounted() {
},

};
</script>
<style >

</style>