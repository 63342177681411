<template>
    <div>
        <v-container grid-list-xs>
        <v-row>
            <v-col align="center">
            <h1 style="color:#77A662"  class="compHead">
                sponsors
            </h1>
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col align="center" cols="12">
            <b><h4  class="partnername" style="color:#E4A837">PLATINIUM</h4></b>
            </v-col>
            <v-col cols="12" align="center">
                <v-img aspect-ratio="4.7"  class="img-responsive programvend" src="../../assets/logos/platinium.png"  >
                </v-img>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col align="center" cols="12">
            <b><h4  class="partnername" style="color:#E4A837">GOLD</h4></b>
            </v-col>
            <v-col cols="12" align="center">
                <v-img aspect-ratio="4.7"  class="img-responsive programvend" src="../../assets/logos/gold.png"  >
                </v-img>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col align="center" cols="12">
            <b><h4  class="partnername" style="color:#E4A837">SILVER</h4></b>
            </v-col>
            <v-col cols="12" align="center">
                <v-img aspect-ratio="4.7"  class="img-responsive programvend" src="../../assets/logos/silver.png"  >
                </v-img>
            </v-col>
        </v-row>
        </v-container>
    </div>  
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
<style>

@media (max-width: 767px) {
    .partnername{
      font-size: 1rem!important;  
    }
}

</style>