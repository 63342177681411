<template>
    <div>
        <v-card>
        <div class="date p-2">
            9H00-11H00 <strong>| SESSION 1</strong>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">Concepts généraux</b><br>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">09H00-09H30</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12"  align="left" >
                        <span class="sessionTitre ">To err is humain</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/ghassane.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr El Adib Ahmed Rhassane, marrakech </span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">09H30-10H00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Compétences péri-opératoires en simulation</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/nassik.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Nassik Hicham, agadir</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">10H00-10H30</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Anesthesia crisis resource management</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Boukatta Brahim, Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">10H30-11H00</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Critical care crisis resource management</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/dendane.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Dendane Tarek, Rabat</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">11:00-11:30</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <div class="date p-2">
            11H30 -13H00 <strong>| SESSION 2</strong>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">Crisis Ressource Management au quotidien</b><br>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">11H30-12H00</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12"  align="left" >
                        <span class="sessionTitre ">Vidéo 1 & debriefing (team)</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">12H00-12H30</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Vidéo 2 & debriefing (team)</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">12h30-13H00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Le quotidien ! (team)</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">13:00-14:30</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-silverware-fork-knife</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-DÉJEUNER </b>
                        <v-icon color="black">mdi-silverware-fork-knife</v-icon>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <div class="date p-2">
            14H30-16H00 <strong>| SESSION 3</strong>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">Réalité virtuelle & intelligence artificielle</b><br>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">14h30-15h00 </b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12"  align="left" >
                        <span class="sessionTitre ">Réalité virtuelle en anesthésie réanimation</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/mouhaoui.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Mouhaoui Mohammed, casablanca</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15H00-15H30</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Comment établir un projet d’intelligence artificielle </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Riffi Jamal, Faculté des sciences, Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h30-16H00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Ça m’intéresse, que faire ? (Team)</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <div class="sessionParallele p-2 text-center">
            <b class="sessionTitre " >SESSION 2 : 29 SEPTEMBRE 2022 En parallèle</b><br>
            <b class="sessionTitre ">Workshop à l’hôpital (CHR Al Ghassani)</b><br>
            <b>ALR périphérique : de la théorie à la pratique</b><br>
            <v-row class="p-3">
                <v-col cols="12" md="12" sm="6" align="center" class="" style="text-justify: inter-word;">
                    <b class="ml-1" style="color:#D25C56"> Dr Marouane Abdellatif (Versailles, France) -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr El Harrar Rachid (Casablanca) -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr El Bouazzaoui Abderrahim (Fès) -</b>
                </v-col>
            </v-row>
                <b class="heurire p-1">08h30 - 10h00 : partie théorique</b><br>
                <b class="heurire p-1">10h30 -15h30 : pratique au bloc opératoire</b>
        </div>
        </v-card>
    </div>
</template>
<script>
export default {

    metaInfo() {
        return{
        
      }
    },
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },
    
}
</script>
<style >

.sessionTitre{
    color:#51644E;
    font-size: 1.2em;
    font-weight: 500;
}
.titreline{
    margin-left: 60px!important;
    color: #231F20;
    font-size:1.5em 
}
.titreline2{
    margin-left: 60px!important;
    color: #1B84A5;
    font-size:1.5em 
}

.heurire{
    max-width: 120px;
    color: white;
    background-color: #97CB94;
    font-size: 16px;
    text-align: center;
}

.heurire2{
    max-width: 120px;
    color: white;
    background-color: black;
    font-size: 16px;
    text-align: center;
}
.date{
    font-family: 'Montserrat', sans-serif;
    color: white;
    background-color: #51644E;
    font-size: 19px;
}

.sessionParallele{
    font-family: 'Montserrat', sans-serif;
    color: black;
    border:5px solid #51644E;
    border-radius: 5px;
    font-size: 19px;
}

.pauseLine{
    font-size: 1.3em;
}

@media all and (max-width: 768px) {
  
  .horaire{
      font-size: 0.9em;
      padding-left: 1px!important;
    }
    .titreline{
        margin-left: 60px!important;
        color: #231F20;
        font-size:1em 
    }
    .titreline2{
        margin-left: 20px!important;
        color: #1B84A5;
        font-size:1.2em 
    }

    .sessionTitre{
        font-size: 0.9em;
    }
    .pauseLine{
        font-size: 0.9em;
    }
  
}


@media all and (max-width: 280px) {
  
  
    .sessionTitre{
        font-size: 0.6em;
    }
  
}


</style>