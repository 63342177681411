<template>
    <div class="header" >
        <!-- This div is  intentionally blank. It creates the transparent black overlay over the video which you can modify in the CSS -->
        <div class="overlay"></div>

        <!-- The HTML5 video element that will create the background video on the header -->
        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src="/video.mp4" type="video/mp4">
        Votre navigateur ne prend pas en charge la vidéo HTML.
        </video>

        <!-- The header content -->
        <v-container>
            <v-row align="center"> 
                <v-col cols="12" md="6">
                    <v-row   data-aos-once="true" data-aos="flip-left">
                        <v-col cols="12" align="center">
                            <img  class="img-responsive congresBrand"  src="..\..\assets\brand.png"  >
                        </v-col>
                    </v-row >
                </v-col>
                <v-col cols="12" md="6" @click="imgdialog = true" align="center">
                    <img  class="img-responsive center afficheAdn"  src="https://drive.google.com/uc?id=165Pe6NSIzi_Cv0iwg57jRqCjr6l7WGLZ&export=download" id="myImg" alt="AMARF">
                    <!-- <img  class="img-responsive center afficheAdn" @click="imgdialog = true"  src="..\..\assets\4.jpg" id="myImg" alt="hakim"> -->
                    <v-dialog  v-model="imgdialog" max-width="600">
                        <img  class="img-responsive center "   src="https://drive.google.com/uc?id=165Pe6NSIzi_Cv0iwg57jRqCjr6l7WGLZ&export=download" alt="hakim">
                    </v-dialog>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

  export default {
    name: 'accueil',

    mounted() {
    },
  data() {
    return {
        now: Math.trunc((new Date()).getTime() / 1000),
        date:"2021-12-25 00:00:00",
        imgdialog:false,
    }
  },
  computed: {
  },
  methods:{
    click: function() {
        this.$refs.inscriComponent.setValue();

        this.$gtag.event('form d\'inscription', {
        'event_category': 'fr',
        'event_label': 'dernier click',
        'value': 1
      })
    },
  }
  }
</script>
<style >
.block {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.cardimg{
    z-index: 1!important;
}
.tiemCard{
  padding: 10px;
  width:500px
}
.agenda{
  width:500px
}
.digit {
    font-family: 'Roboto', serif;
    margin-top: 20%;
}
.time {
    font-size: 1em;
    font-family: 'Roboto', serif;
    background-color:#ffffff;
    border-radius: 15px;
}
.datetime{
  font-family: 'Oswald', sans-serif;
  color:#cca900
}
.view{
  text-align: center;
}
.afficheAdn{
  max-width: 350px!important;
}
v-img.back{
  position: relative;
}
nav.bottomNav{
  position: absolute;
  width: 100%;
  bottom: 100px;
}
.head1{
  font-size: 2.5em;
  font-family: 'Almarai', sans-serif !important;
}
.head2{
  font-size: 1.6em;
}
.backImg{
  height: 600px!important;
}
.headOpacity{
  background: rgba(0, 0, 0, 0.6)!important;
  height: 600px!important;
}
.v-parallax__content{
  padding: 0px 0px 0px 0px!important;
}


/* Large desktop */
@media (min-width: 1250px) {
  .header {
    height: 90vh;
  }
}
/* Large desktop */
@media (min-width: 1200px) {
    .header {
        height: 100vh;
    }
}



@media all and (max-width: 768px) {
    nav.bottomNav{
        bottom: 9px;
    }
  .close {
        position: absolute;
        top:35px;
    }
  .backImg .headOpacity{
        height: 650px!important;
    }
  .head1{
    font-size: 1.53em;
    }
  .head2{
        font-size: .8em;
    }
  .congresBrand{
        max-width: 360px!important;
    }
  
}


/* Portrait tablet to landscape and desktop */
@media (min-width: 766px) and (max-width: 979px) {

  .close {
    top:40px;

  }

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .afficheAdn{
    max-width: 250px!important;
  }

}


/* Landscape phones and down */
@media (max-width: 480px) {
    .time {
        font-size:0.7em
    }
    .digit{
        margin-top: 25%;
    }
    .tiemCard{
        width:400px
    }
    .agenda{
        width:400px
    }
    .datetime{
        font-size:1.3em
    }
    .header {
        position: relative;
        background-color: black;
        height: 90vh;
        min-height: 25rem;
        width: 100%;
        overflow: hidden;
    }
}
@media (max-width: 376px) {
    .header {
        height: 100vh!important;
    }
}



.flip-clock__slot {
  color: #cca900;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.text-block {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
/*header video */
.header {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.header .container {
  position: relative;
  z-index: 2;
}

.header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

@media (pointer: coarse) and (hover: none) {
 
.header {
  position: relative;
  background-color: black;
  height: 90vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
  
}

@media (max-width: 545px) {
  .header {
    height: 120vh;
  }
}
@media (max-width: 480px) {
.header {
  height: 110vh;
}
}

@media (max-width: 414px) {
  .afficheAdn{
    max-width: 350px!important;
  }
  .header {
    height: 120vh;
  }
}

@media (max-width: 376px) {
  .header {
    height: 135vh;
  }
  .afficheAdn{
    max-width: 300px!important;
  }
}
@media (min-width: 766px) and (max-width: 979px) {
  .header {
    height: 60vh;
  }
 
}
</style>
