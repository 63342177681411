<template>
    <v-container class="inscrire" grid-list-xs >
        <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#77A662"  >
                    Inscription
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-snackbar  v-model="snackbar" :color="snackColor"  timeout="5000" top>
            {{ msg }}
        </v-snackbar>
        <v-card class="pa-1">
            <v-card-title>
            <span class="headline" style="white-space: nowrap;">Formulaire d'inscription</span>
            </v-card-title>
            <v-list class="overflow-y-auto" >
            <v-card-text>
                <v-container v-if="!confirmed">
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Nom*"
                            :rules="allRule"
                            v-model="nom"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Prenom*"
                            :rules="allRule"
                            v-model="prenom"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" >
                            <v-text-field label="Email*"
                            :rules="emailRules"
                            v-model="email"
                            class="my-input"
                            onpaste="return false"
                            autocomplete="nope"
                            @keydown.space.prevent
                            required>
                            </v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="GSM*"
                            :rules="allRule"
                            type="tel"
                            v-model="tlf"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-text-field label="Ville*"
                            :rules="allRule"
                            v-model="ville"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <strong>Prise en charge*</strong>
                            <v-radio-group v-model="encharge" :rules="allRule" row>
                                <v-radio label="Oui" value="oui"></v-radio>
                                <v-radio label="Non" value="non"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-select
                                :items="fonctionsList"
                                label="Fonction*"
                                v-model="specialite"
                                required
                                :rules="allRule"
                            ></v-select>
                        </v-col>
                        <v-col  cols="12" md="6">
                            <strong class="sessionTitre" style="color:#77A662">Jeudi 29/09/2022 :</strong><br><br>
                            <strong class="sessionTitre">Choisissez une sessions</strong>
                            <v-radio-group v-model="session"  column>
                                <v-radio label="1ère Journée du Club SIRI de la SMAAR (Session pleine)" value="oui" disabled></v-radio>
                                <v-radio label="Wokshop ALR périphérique" value="workshop" :disabled="detectFonction()"></v-radio>
                                <v-radio label="Sans session" value=" " ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col  cols="12" md="6">
                            <strong class="sessionTitre" style="color:#77A662">Vendredi 30/09/2022 :</strong><br><br>
                            <strong class="sessionTitre">Choisissez un atelier </strong>
                            <v-radio-group v-model="atelier"  column>
                                <v-radio label="ATELIER 1 : Doppler transcranien en neuroréanimation" value="1"></v-radio>
                                <v-radio label="ATELIER 2 : Monitorage et gestion de la curarisation en anesthésie" value="2"></v-radio>
                                <v-radio label="ATELIER 3 : Fast Echo en pratique" value="3"></v-radio>
                                <v-radio label="ATELIER 4 : Gestion des voies aériennes supérieures" value="4"></v-radio>
                                <v-radio label="Sans atelier" value=" "></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-alert outlined type="warning" prominent border="left" dark>
                            <b style="color:#3F8EBC" class="pauseLine">Frais d’inscription :</b><br>
                            <span class="text">
                                - Médecins : 1000 Dhs<br>
                                - Résidents et internes : 300 Dhs<br>
                                - Infirmiers : 300 Dhs<br>
                                Inscription = Accès conférence + ateliers (dans la limite des places disponibles)+ déjeuners et pauses
                                cafés du 30 septembre et 1 octobre.<br>
                            </span>
                            <span style="color:red">N.B : Internes et résidents avec travail accepté : inscription gratuite sans hébergement.</span>
                        </v-alert>
                        <v-col  cols="12">
                            <strong class="sessionTitre">Choisissez les options de votre packs* </strong>
                            <v-radio-group v-model="pack" :rules="allRule" column>
                                <v-radio label="Inscription seule : médecin : 1000 Dhs" value="Inscription seule : médecin : 1000 Dhs"></v-radio>
                                <v-radio label="Inscription seule : résidents et internes : 300 Dhs" value="Inscription seule : résidents et internes : 300 Dhs"></v-radio>
                                <v-radio label="Inscription seule : Infirmiers : 300 Dhs" value="Inscription seule : Infirmiers : 300 Dhs"></v-radio>
                                <v-radio label="Pack 1 : Inscription + 2 nuits en chambre single : 4000 Dhs" value="Pack 1 : Inscription + 2 nuits en chambre single : 4000 Dhs"></v-radio>
                                <v-radio label="Pack 2 : Inscription + 2 nuits en chambre double : 2500 Dhs" value="Pack 2 : Inscription + 2 nuits en chambre double : 2500 Dhs"></v-radio>
                                <v-radio label="Pack 3 : Inscription + 3 nuits en chambre single : 5500 Dhs" value="Pack 3 : Inscription + 3 nuits en chambre single : 5500 Dhs"></v-radio>
                                <v-radio label="Pack 4 : Inscription + 3 nuits en chambre double : 3500 Dhs" value="Pack 4 : Inscription + 3 nuits en chambre double : 3500 Dhs"></v-radio>
                                <v-radio label="Pack 5 : Inscription + 4 nuits en chambre single : 7000" value="Pack 5 : Inscription + 4 nuits en chambre single : 7000"></v-radio>
                                <v-radio label="Pack 6 : Inscription + 4 nuits en chambre double : 4500 Dhs" value="Pack 6 : Inscription + 4 nuits en chambre double : 4500 Dhs"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-container>
                <v-container v-else>
                    <v-alert class="text" text>
                        Vos informations sont bien enregistrées, vous allez recevoir aussi une confirmation par Email.<br>
                        Afin de compléter votre inscription, veuillez-vous rendre au stand de secrétariat du congrès Jeudi 29 Septembre 2022 à partir de 18h à Hôtel Marriott à Fès.<br>
                        Bon congrès.
                    </v-alert>
                </v-container>
                <v-alert text prominent type="error" v-show="formNotValid" icon="mdi-cloud-alert">
                    Formulaire non complet
                </v-alert><br>
            </v-card-text>
            </v-list>
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="validate"
                    v-if="!confirmed"
                    :loading="inscribtn"
                >
                    S'inscrire
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
   data () {
      return {
        inscribtn:false,
        email:'',
        emailConferm:'',
        nom:'',
        prenom:'',
        ville:'',
        tlf:'',
        specialite:'',
        encharge:'',
        session:'',
        atelier:'',
        pack:'',
        valid: true,
        dialog: false,
        confirmed: false,
        error:false,
        snackbar:false,
        snackColor:'',
        msg:'',
        formNotValid:false,
        
        emailRules: [
            v => !!v || 'E-mail est requis',
            v => /.+@.+/.test(v) || 'L\'email doit être valide',
        ],

        allRule: [v => !!v || 'Ce champ est requis',],
        domaineRule: [(v) => !!v || "Item is required"],
        select: { name: 'Morocco', code: 'MA' },
        fonctionsList: [
            'Professeur',
            'Spécialiste secteur public',
            'Spécialiste secteur privé',
            'Résident/interne',
            'Infirmier'
        ],
      }
    },
    mounted() {
     
    },
    methods: {
        validate () {
            if(this.$refs.form.validate()){
                this.inscribtn = true
                this.email = this.email.toLowerCase()
                this.axios({
                    method: 'post',
                    url: 'https://amarf2022i.hashtagsante.ma/api/inscrit',
                    data: {
                        email:this.email ,
                        name:this.nom,
                        prenom:this.prenom,
                        tlf:this.tlf,
                        ville:this.ville,
                        encharge:this.encharge,
                        specialite:this.specialite,
                        session:this.session,
                        atelier:this.atelier,
                        pack:this.pack,
                    }
                }).then((res)=>{
                    if(res.data !='succes'){
                        this.snackMsg(res.data)
                        this.error=false
                        this.inscribtn=false
                    }
                    else{
                        this.clear()             
                        this.confirmed = true
                        this.error=false
                        this.inscribtn=false
                    }
                }, err =>{
                    if(err){
                    this.emailError('Cet email déjà inscrit')
                    this.inscribtn=false
                    }
                });
            }
            else{
                this.formNotValid = true
            }
        },
        clear(){
            this.confirmed = false
            this.email = '',
            this.emailConferm='',
            this.nom = '' , 
            this.prenom = '' , 
            this.ville = '' , 
            this.specialite= ''
            this.encharge = '' , 
            this.session = '' , 
            this.pack = '' , 
            this.tlf = '' , 
            this.atelier= '',
            this.formNotValid = false
        },
        detectFonction(){
            if(this.specialite === "Infirmier" || this.specialite === "Résident/interne"){
                this.session = ''
                return true
            }
            else{
                return false
            }
        },
        snackMsg(msg){
            msg == "succes" ? this.snackColor = "green" : this.snackColor = "red"
            msg == "succes" ? this.msg = " Cher Docteur,\nVous êtes bien inscrit, veuillez vérifier votre boite Email " : this.msg = msg 
            this.snackbar = true
        },
        emailError(msg){
            this.snackbar = true
            this.snackColor = "red"
            this.msg =  msg 
        }
    },
  }
</script>
<style>
h1{
 font-family: 'Montserrat';
}
.inscrireInfo{
    font-size:1.5em
}
@media (max-width: 480px) {
  
}
@media all and (max-width: 768px) {
  .btn{
    font-size:10px
    }
    .insbtn{
        font-size:1em
    }
    .textInsc{
        font-size:10px
    } 
}



.my-input input{
  text-transform: lowercase
}

.welcome_text{
  font-size: 1.5em;
}    
.btn{
    background-color: #FBB400 !important;
    border-radius: 10px;
}
.insbtn{
        font-size:30px

    }
.textInsc{
    font-size:20px
}  

.ps {
  height: 400px;
}

@media (max-width: 480px) {
    .insbtn{
        font-size:15px
    }
}
</style>