<template>
    <div>
        <v-card>
        <div class="date p-2">
            <strong>SESSION MATIN</strong>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">Ateliers et communications orales</b><br>
            </v-col>
        </v-row>
        
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">9h00-10h30</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12" align="left" >
                        <strong class="sessionTitre ">Communications orales : </strong><br>
                        <span class="sessionTitre " style="color:#000">• Salle 1 : Anesthésie</span><br>
                        <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Houari Nawfal, Fès</span>
                        </a><br>
                        <span class="sessionTitre " style="color:#000">• Salle 2 : Réanimation</span><br>
                        <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Bichri Brahim (Fés)</span>
                        </a><br>
                        <span class="sessionTitre " style="color:#000">• Salle 3 : Pédiatrie et obstétrique</span><br>
                        <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Benlamkaddem Said (Fés)</span>
                        </a><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">10:30-11:00</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon><br>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> Visite des stands et e-posters  </b>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">11h00-12h30</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <strong class="sessionTitre " style="color:#408DBE">ATELIER 1 : </strong>
                        <span class="sessionTitre ">Doppler transcranien en neuroréanimation:   </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/shimi.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Shimi Abdelkarim</span>
                        </a><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/derkaoui.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Derkaoui Ali (Fès)</span>
                        </a>
                    </v-col>
                    <v-col cols="12" align="left" >
                        <strong class="sessionTitre " style="color:#408DBE">ATELIER 2 : </strong>
                        <span class="sessionTitre ">Monitorage et gestion de la curarisation en anesthésie: </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/bouderka.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Bouderka Moulay Ahmed (Casablanca)</span>
                        </a>
                    </v-col>
                    <v-col cols="12" align="left" >
                        <strong class="sessionTitre " style="color:#408DBE">ATELIER 3 : </strong>
                        <span class="sessionTitre ">Fast Echo en pratique:</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/kettani_ali.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Kettani Ali (Rabat), </span>
                        </a><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/belkhadir.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Belkhadir Zakaria(Rabat).</span>
                        </a>
                    </v-col>
                    <v-col cols="12" align="left" >
                        <strong class="sessionTitre " style="color:#408DBE">ATELIER 4 : </strong>
                        <span class="sessionTitre ">Gestion des voies aériennes supérieures : </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/qamouss.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Qamouss Youssef(Marrakech), </span>
                        </a><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/essatara.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Dr Essatara Yassine (Tanger).</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        <div class="date p-2">
            <strong>Session Après-midi</strong>
        </div>
        <v-row class="p-3">
            <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                <b style="color:#3F8EBC" class="pauseLine">Thème principal : L’anesthésie et l’analgésie locorégionale
                    (ALR) en anesthésie-réanimation obstétricale
                </b><br>
                <b style="color:#D25C56">Modérateurs :</b><b class="ml-1" style="color:#D25C56"> DR TAHRI CHAFIQ -</b>
                <b class="ml-1" style="color:#D25C56"> Pr El Adib Ahmed Rhassane -</b>
                <b class="ml-1" style="color:#D25C56"> Pr Tachinante Rajae - </b>
                <b class="ml-1" style="color:#D25C56"> Dr Lafrayji Zakariya</b>
            </v-col>
        </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">14H50-15H00</b>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col cols="12" md="9" sm="12"  align="left" >
                        <span class="sessionTitre ">Mot d’ouverture</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/adnane.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Berdai Mohamed Adnane ,Fés</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h00-15h25</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Comment réussir l’ALR pour l’analgésie de l’accouchement par voie basse </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/rebahi.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Rebahi Hossam, ,Marrakech</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h25-15h50</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">L’anesthésie pour césarienne : intérêt et modalités de l’ALR.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/adnane.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Berdai Mohamed Adnane ,Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">15h50-16h15</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Gestion de l’hypotension artérielle secondaire à l’ALR en obstétrique</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/avt.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Hosni Brahim ,Oujda</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="black" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire2 p-1 ">16:15-16:45</b>
                    </v-col>
                    <v-col  cols="11" align="center" >
                        <v-icon color="black">mdi-coffee</v-icon>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> PAUSE-CAFÉ </b>
                        <v-icon color="black">mdi-coffee</v-icon><br>
                        <b style="color:#000" class="pauseLine ml-3 mr-3"> Visite des stands et e-posters  </b>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
            <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#D25C56">Modérateurs :</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Hosni Brahim -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr El Youssoufi Smael -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Salmi Said</b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">16h45-17h10</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="10" sm="12" align="left" >
                        <span class="sessionTitre ">Symposium : La place du fibrinogène dans la prise en charge de l’hémorragie du post partum.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/adnane.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Berdai Mohamed Adnane, Fès</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" align="right">
                        <img width="120" src="../../assets/logos/hemo.jpg" alt="Fabienne"> 
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
            <v-row class="p-3">
                <v-col cols="12" md="12" sm="12" align="center" class="" style="text-justify: inter-word;">
                    <b style="color:#D25C56">Modérateurs :</b><b class="ml-1" style="color:#D25C56"> : Pr Harandou M -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Bouaggad Abderrahman -</b>
                    <b class="ml-1" style="color:#D25C56"> Pr Miguil Mohamed - </b>
                </v-col>
            </v-row>
        <v-timeline align-top dense>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">17h10-17h35</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Place de l’ALR dans l’analgésie de la césarienne.</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/harandou.jpg" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Harandou Mustapha, Fès</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">17h35-18h00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Troubles de l’hémostase et ALR en obstétrique. </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/salmi.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Salmi Said, Casablanca</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">18h00-18h25</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Toxicité des anesthésiques locaux en obstétrique</span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/miguil.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs">Pr Miguil Mohamed ,Casablanca</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#51644E" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">18H25-19H00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Conférence d’inauguration
                            Histoire de l’anesthésie réanimation au Maroc. </span><br>
                        <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                            <v-avatar>
                                <img src="/speakers/maazouzi.png" alt="Fabienne">
                            </v-avatar>
                            <span class="ml-2 orateurs"> Pr Maazouzi Wajdi (Rabat)</span>
                        </a>
                    </v-col>
                </v-row>
            </v-timeline-item>
            <v-timeline-item  color="#97CB94" small>
                <v-row class="pt-1">
                    <v-col cols="12">
                        <b class="heurire p-1 ">19h00</b>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="left" >
                        <span class="sessionTitre ">Hommage et mot de la fin</span><br>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>
        </v-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            isImageLoaded: false,
            panel: 0
           
        }
    },
    props: ['slideshow'],
    components: {
    },
    methods: {
      hideHand(){
        this.animation = false
      },
      onImageLoad () {
        this.isImageLoaded = true
        }
    },

    mounted() {
      
    
  },
}
</script>
<style>
@media all and (max-width: 768px) {

  .descriplist{
    font-size: .7em;
 }
}
</style>