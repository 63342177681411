<template>
	<div>
		<v-container grid-list-xs>
			<v-row>
			<v-col align="center">
				<div  class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
					<iframe class="responsive-iframe p-1"  src="https://www.youtube-nocookie.com/embed/XlbCVjOZav8"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</v-col>
			</v-row>
		</v-container>
	</div>  
</template>
<script>
export default {
  data() {
    return {
    }
  },
}
</script>
<style >
.responsive-iframe{
	border-radius: 5%;
	background-color: #77A662;
}

.iframe-container{
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: -10px;
        height: 600px;
        /* padding-top: 56.25%; */
    }
    .responsive-iframe {
        /* position: absolute; */
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>