<template> 
    <v-container class="theme">
        <v-row>
            <v-col align="center">
            <h1 style="color:#77A662"  class="compHead">THÈMATIQUES</h1>
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row class="safariFlex">
            <v-col cols="12" md="6" sm="12" align="center" class=" safariFlex-colum"  >
                <v-img  src="https://drive.google.com/uc?id=1hnIIRh8T6Cigx44Cu7_Rw7yHYBkvNeex&export=download"
                data-aos-once="true"
                aspect-ratio="1.4"
                alt="REGENERATION URBAINE"
                data-aos="fade-down" > 
                </v-img>
            </v-col>
            <v-col cols="12" md="6" sm="12"  class="pa-md-10  safariFlex-colum"  >
                <div class="list-type1">
                    <ol>
                        <li data-aos="fade-right" data-aos-once="true"  data-aos-duration="1500"><a href="#">L'hemodynamique des etats de choc</a></li>
                        <li data-aos="fade-right" data-aos-once="true"  data-aos-duration="1600"><a href="#">Les infections nosocomiales en reanimation</a></li>
                        <li data-aos="fade-right" data-aos-once="true"  data-aos-duration="1700"><a href="#">L'anesthesie en obstetrique</a></li>
                        <li data-aos="fade-right" data-aos-once="true"  data-aos-duration="1800"><a href="#">La simulation en anesthesie-reanimation</a></li>
                        <li data-aos="fade-right" data-aos-once="true"  data-aos-duration="1900"><a href="#">L'anesthesie locoregionale peripherique</a></li>
                    </ol>
                </div>
            </v-col>
        </v-row>
    </v-container>  
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
<style>

.list-type1{
    width:100%;
    margin:0 auto;
}

.list-type1 ol{
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    font-size: 1.4em;
    font-family: 'Raleway', sans-serif;
    padding: 0;
    margin-bottom: 4em;
}
.list-type1 ol ol{
    margin: 0 0 0 2em;
}

.list-type1 a{
    position: relative;
    display: block;
    color: white!important;
    padding: .4em .4em .4em 1em;
    *padding: .3em;
    margin: .5em 0;
    background: #93C775;
    color: #000;
    text-decoration: none;
    -moz-border-radius: .3em;
    -webkit-border-radius: .3em;
    border-radius: 10em;
    transition: all .2s ease-in-out;
}

.list-type1 a:hover{
    background: #d6d4d4;
    color: #614F45!important;
    text-decoration:none;
    transform: scale(1.1);
}

.list-type1 a:before{
    content: " ";
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background:#93C775;
    height: 2em;
    width: 2em;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    border-radius: 2em;
    color:#FFF;
}
.safariFlex{
    margin: auto;
}

   .orderFix #bf   {order: 1;}
    .orderFix #af {order: 2;}
    .orderFix #df   {order: 1;}
    .orderFix #cf {order: 2;}

    /* .orderFix #bf   {-webkit-order: 1;}
    .orderFix #af {-webkit-order: 2;}
    .orderFix #df   {-webkit-order: 1;}
    .orderFix #cf {-webkit-order: 2;} */



@media screen and (min-width: 770px) {

    .safariFlex{
        display: flex;
        display: -webkit-box;
         display:-webkit-flex; 
    }
    .safariFlex-colum{
        flex: 0 0 50%;
    }
    .safariFlex-colum-foter{
         flex: 0 0 70%;
    }
  

}
@media (min-width: 992px) and (max-width: 1199px) {
  
    .list-type1{
        width:450px;
        margin:0 auto;
    }
    .list-type1 ol{
        font-size: 1em;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
  
  .list-type1{
      width:350px;
      margin:0 auto;
  }
  .list-type1 ol{
      font-size: 1em;
  }
  .theme {
        max-width: 820px!important;
    }
}
@media all and (max-width: 767px) {

    .orderFix #bf   {order: 2;}
    .orderFix #af {order: 1;}
    .orderFix #df   {order: 2;}
    .orderFix #cf {order: 1;}

    /* .orderFix #bf   {-webkit-order: 2;}
    .orderFix #af {-webkit-order: 1;}
    .orderFix #df   {-webkit-order: 2;}
    .orderFix #cf {-webkit-order: 1;} */

    .propotext{
        font-size: 0.8em;
    }
    .list-type1{
        width:350px;
        margin:0 auto;
    }
    .list-type1 ol{
        font-size: 1em;
    }
}

@media (max-width: 376px) {
    .list-type1{
        width:300px;
        margin:0 auto;
    }
    .list-type1 ol{
        font-size: 0.7em;
    }
}



</style>