<template>
<v-parallax src="https://drive.google.com/uc?id=1T2ZX885xAT2DeME3m6B8NLtPj1-8OS2F&export=download" class="comiteparrp mt-5">
    <div class="opaciteSeparat">
        <v-container>
            <v-row>
                <v-col cols="12"  align="center">
                    <img  class="img-responsive " src="..\..\assets\brand.png" >
                </v-col>
            </v-row>
        </v-container>
    </div>
</v-parallax>  
</template>
<script>
export default {
    data() {
        return {
            speakers: [
                 {name:"Aawatif Hayar",cv:"Présidente de l’Université Hassan II de Casablanca",
                  src:"awatif.png",},
                {name:"Hassan Radoine",cv:"Directeur Ecole d'Architecture, planification et design de l’ Université Mohammed VI Polytechnique.",
                  src:"hassan.png",},     
            ],
        }
    },
}
</script>
<style >
.compHead{
    font-size: 1.9375rem;
}
.comiteparrp{
    height: 330px!important;
}
.opaciteSeparat{
  background-color: rgba(0, 0, 0, 0.577);
  height: 400px!important;
}
@media all and (max-width: 768px) {

  .comiteparrp{
    height: 200px!important;
 }
}

</style>