<template>
<div>
    <nav style="background-color:#77A662;height:50px" class="pa-2 ">
        <v-layout >
            <v-flex>
                <b style="color:black;" class="titleDate">Rendez-vous du <vue-typer text=' 29 SEPTEMBRE AU 01 OCTOBRE 2022' ></vue-typer></b>
            </v-flex>
            <v-flex style="text-align:right">
                <a href="https://hashtagsante.ma/" target="_blank" style="text-decoration:none;" class="contactAdn titleDateAs ">Réalisé par hashtagsante.ma</a><br>
            </v-flex>
        </v-layout>
    </nav>
    <nav style="background-color:white;z-index:9;" id="myHeader" class="navbar navbar-light navbar-expand-md bg-faded justify-content-center sticky-top ">
    <a href="#" class="d-flex  mr-auto">
        <img  class="img-responsive logonav" src="../assets/logoAmarf.png"  >
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
        <ul class="nav navbar-nav  ml-auto justify-content-center first">
            <li>
            <a href="#propos">
                <b>MOT DU PRÉSIDENT</b>
            </a>
            </li>
            <li>
            <a href="#theme">
                <b>THÉMATIQUES</b>
            </a>
            </li> 
            <li>
            <a href="#agenda">
                <b>PROGRAMME</b>
            </a>
            </li> 
            <li>
            <a href="#speakers">
                <b>SPEAKERS</b>
            </a>
            </li> 
            <li>
            <a href="#inscription">
                <b>INSCRIPTION</b>
            </a>
            </li> 
            <li class="LGactive ml-md-3 inscriBtn" style="background-color:#E30525!important;">
            <a href="https://amarf2022i.ascreacongres.ma/" target="_blank">
                <b style="color:white">SOUMISSION DES ABSTRACTS</b>
            </a>
            </li>
            <li>
            <a href="#sponsors">
                <b>SPONSORS</b>
            </a>
            </li> 
            <li>
            <a href="#contact">
                <b>CONTACT</b>
            </a>
            </li>
        </ul>
    </div>
    <v-dialog v-model="dialog" width="500">
        <v-card>
        <v-card-title class="text-h5 ">
            Cher visiteur
        </v-card-title>
        <v-col>
            La VOD sera bientôt disponible
        </v-col>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  color="primary"  text  @click="dialog = false">
            Ferme
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </nav>
</div>
</template>

<script>
export default {

  data: () => ({
    scrollPosition: null,
    slideshow:"",
    dialog:false,
    vid:"",
    audio:true,
    headclass:'sticky'
  }),
  methods: {
            updateScroll() {
                var header = document.getElementById("myHeader");
                var sticky = header.offsetTop;

                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky")
                } else {
                    header.classList.remove("sticky");
                }
            },
            goTo(path){
                this.$router.push(path)
            },
            click: function() {
                this.$refs.inscriComponent.setValue();
            },
        
    },
mounted() {
    window.addEventListener('scroll', this.updateScroll);

   
},
};
</script>
<style >
html{
  scroll-behavior: smooth;
}
.content {
  padding: 16px;
}

.sticky {
  position: absolute!important;
  position: fixed!important;
  width:100%;
  z-index: 9;
}
.first li a{
  color:#000!important;
  transition: 0.3s;
}
.first li a:hover{
  color:white!important;
  background-color:#77A662!important
}

.LGactive li a{
  color:white!important;
  background-color:#E30525!important;
  padding:5px
}
.LGactive li a:hover{
  background-color:#77A662!important;
}
.logonav{
  width: 75px;
}
.footpara {
  height: 270px!important;
}
.footparashad{
  background-color: rgba(60, 179, 113, 0.777);
  height: 400px!important;
}
.contactAdn{
  color: white!important;;
}
a:hover{
  color: darkorange!important;;
}

.titleDateAs{
    font-size: 15px;
  }

@media (min-width:900px) {

}


@media (min-width: 1200px) {

}

@media (min-width: 979px) and (max-width: 1326px) {
 
  .first li a{
		font-size: 11px!important;
    padding: 12px!important;
	}

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .logonav{
    width: 60px;
  }
  .first li a{
		font-size: 9px!important;
    padding: 6px!important;
	}

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .inscriBtn{
      width:300px
    }
    .text{
    font-size:3vw;
    text-justify: distribute;
    }
    .Logofoter{
      width:250px
    }
     .logonav{
      width: 75px;
    }
    .footpara {
     height: 490px!important;
    }
    .footparashad{
      padding: 10px;
      height: 600px!important;
    }
}


.footer{
    margin-top:100px;
}
.footText{
  color:white;
  text-align:right;
  font-size: 0.55em;
  color: #fff;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
}
v-img.sponsor{
  position: relative;
}
.footTitle{
    text-align:left;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.0rem;
    font-weight: 500
}
.titlefootline{
  content: "";
    display: block;
    width: 4.375rem;
    height: .25rem;
    background-color: red;
    bottom: 0;
    right: 0;
}
.social{
  position: absolute;
  right: -5px!important;
  position: fixed;
  width:50px;
  top: 30%;
  z-index: 9;
}
.navbar{
  margin-bottom:0px!important
}
.inscriBtn{
  background-color:#FBB400!important
}
.liveBtn{
  background-color:#ff0800!important;
  color:white!important
}
.vue-typer .custom.char.typed {
  color: #ffffff;
  font-size: 20px;
}
.titleDate{
  font-size: 20px;
}
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .vue-typer .custom.char.typed {
    font-size: 10px;
  }
  .titleDate{
    font-size: 10px;
  }
  .titleDateAs{
    font-size: 10px;
  }
}

@media (max-width: 376px) {
  .vue-typer .custom.char.typed {
    font-size: 7px;
  }
  .titleDate{
    font-size: 7px;
  }
}
</style>