<template>
    <div>
        <v-container grid-list-xs>
        <v-row>
            <v-col align="center">
                <h1 style="color:#F38E3C"  class="compHead">SPEAKERS</h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
            <v-container class="pa-5 carros">
                <carousel :per-page-custom="responsive" :pagination-enabled="false" :navigation-enabled="true"  :scrollPerPage="false" :navigate-to="[slideshow,true]"	>
                    <slide v-for="(speaker, index) in speakers" :key="index" :id="index" @click="hideHand" >
                        <v-tooltip bottom color="#4B3925"  max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-card class="mx-auto" color="grey lighten-4" max-width="300" @click="hideHand" @mouseover="hideHand" v-bind="attrs" v-on="on">
                                    <v-img aspect-ratio="1"  :src="'../../speakers/'+speaker.src" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)">
                                        <div class="d-flex v-card--reveal-first white--text ps-1 speakerName"  >
                                            <b>{{speaker.name}}</b>
                                        </div>
                                    </v-img>
                                </v-card>
                            </template>
                            <span style="text-align:left;" class="cvtext">{{speaker.cv}}</span>
                        </v-tooltip>
                    </slide>
                </carousel>
                <h1 class="pulsate" v-if="animation">
                    <v-icon color="white" large>mdi-gesture-double-tap</v-icon>
                </h1>
            </v-container>
        </v-container>
    </div>  
</template>
  <script>
  import { Carousel, Slide } from 'vue-carousel';
  export default {
      data() {
          return {
              overlay: false,
              selectslide:5,
              speakers: [
                {name:"Pr Abidi Khalid",cv:"PES de Réanimation Médicale./n Président du Club d’Infectiologie de la SMAAR./n Secrétaire Général de Société Marocaine d'Anesthésie, d'Analgésie et de  Réanimation (SMAAR). Membre de la Société de Réanimation Médicale en Langue Française (SRLF) depuis 2004. Reviewer de la revue “Critical Care Medicine”, “BMJ of cases report” et le “Journal of Anesthesiology and Critical Care Medicine”.Membre du “board” de la revue “Intensive Care Medicine”./n Vice Président de la Société de Réanimation de Langue Française",
                  src:"abidi.png"},
                {name:"Pr. Aissaoui Younes",cv:"Chef de service de la réanimation chirurgicale à l’Hôpital militaire Avicenne./n Référent antibiothérapie et membre du Comité de lutte contre l’infection nosocomiale./n Professeur d’anesthésie réanimation à la faculté de médecine de Marrakech./n Membre de la commission recherche à la faculté de médecine de Marrakech./n",
                  src:"aissaoui.png"},
                {name:"Pr Berdai Mohamed Adnane",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Président de l’amicale des médecins anesthésistes réanimateurs de Fès (AMARF)./n Membre du bureau de la société marocaine d’anesthésie, d’analgésie et de réanimation (SMAAR)./n Président du club des anesthésistes réanimateurs pratiquant l’obstétrique (CARPO).",
                  src:"adnane.jpg"},
                {name:"Pr. Belkhadir Zakaria",cv:"Chef de service d'anesthésie réanimation de l'institut national d'oncologie sidi mohamed ben abdellah de Rabat./n Professeur de l'enseignement supérieur de la faculté de médecine et de pharmacie de Rabat./n Président de l'Association des médecins anesthésistes réanimateurs de la région Rabat Salé Kénitra./n",
                src:"belkhadir.png"},
                {name:"Pr. Bouderka Moulay Ahmed",cv:"Réanimateur à la clinique Dar Salam - Casablanca./n Professeur de l’Enseignement Supérieur en Anesthésie-Réanimation, Faculté de Médecine de Casablanca - Président de l’Association Nationale des Cliniques Privées (ANCP) région Grand Casablanca./n Ancien secrétaire général membre de bureau de la Société Marocaine d’Anesthésie Réanimation (SMAR) - Ancien secrétaire général de l’Association des Médecins, Anesthésistes Réanimateurs de Casablanca ( AMARC). Auteur de plusieurs publications dans des revues scientifiques nationales et internationales. ",
                src:"bouderka.png"},
                {name:"Pr Boukatta Brahim",cv:"Dr en Anesthésie-réanimation, CHU Hassan II de Fès Pr à la Faculté de Médecine, Pharmacie et Médecine dentaire de Fès, Université Sidi Mohammed Ben Abdellah",
                src:"boukatta.jpg"},
                {name:"Pr Dendane Tarek",cv:"Professeur de l'enseignement supérieur en Réanimation Médicales. Praticien dans le service de réanimation médicale de l'hôpital Ibn Sina de Rabat./n Membre de l'unité de Formation et de Recherche de Réanimation Médicale et de Médecine expériementale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur et co-auteur d'un grand nombre de projets de recherche à l'échelle nationale et internationale",
                src:"dendane.png"},
                {name:"Pr Derkaoui Ali",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, Pr Derkaoui Ali Université Sidi Mohammed Ben Abdellah, Fès ",
                src:"derkaoui.jpg"},
                {name:"Pr El Adib Ahmed Rhassane",cv:"Chef de Service Anesthésie - Réanimation en Gynécologie - Obstétrique CHU Mohammed VI./n Faculté de Médecine et de Pharmacie - Université Cadi Ayyad , Marrakech. Président de la Société Marocaine de Médecine d'Urgence./n Vice-President of the Moroccan Society of Medical Simulation (Morocco Sim)./n Immediate Past President of the Moroccan Society of Anesthesiology and Intensive Care (SMAR)",
                src:"ghassane.png"},
                {name:"r. El Ahmadi Brahim",cv:"Professeur de l'enseignement supérieur Service d'Anesthésie Réanimation - Institut National d'Oncologie de Rabat./n Faculté de Médecine et de Pharmacie - Université Mohammed V de Rabat",
                src:"brahim_ahmadi.jpg"},
                {name:"Pr El Bouazzaoui Abderrahim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Secrétaire général de l’amicale des médecins anesthésistes  réanimateurs de Fès (AMARF).",
                src:"bouazzaoui.jpeg"},
                {name:"Dr. Hamzaoui Olfa",cv:"Praticien hospitalier temps plein - Service de Réanimation Polyvalente. Hôpital Antoine Béclère, Clamart 92141./n Titulaire de plusieurs diplômes et titres de distinction internationale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur de plusieurs publications dans des revues scientifiques nationales et internationales",
                src:"hamzaoui.png"},
                {name:"Pr. Harandou Mustapha",cv:"Professeur d'anesthésie-réanimation à la faculté de médecine et de  pharmacie, université Sidi Mohammed ben Abdellah à Fès./n Chef du service Réanimation mère et enfant",
                src:"harandou.jpg"},
                {name:"Pr El Harrar Rachid",cv:"Professeur d’enseignement supérieur d’anesthésie- réanimation./n Chef de service de Réanimation des urgences chirurgicales.",
                src:"harrar.png"},
                {name:"Dr Essatara Yassine",cv:"Médecin anesthésiste réanimateur./n Spécialiste en Neuro-réanimation./n Spécialiste en hypnose médicale./n Expert-référent en gestion des voies aériennes difficile.",
                src:"essatara.png"},
                {name:"Pr Ezzouine Hanane",cv:"Professeur de l’enseignement supérieur à la faculté de médecine et de pharmacieUniversité Hassan II - Réanimateur Médical./n Ancien secrétaire général du club d’infectiologie de la SMAAR.",
                src:"ezzouine.png"},
                {name:"Pr Housni Brahim",cv:"PES d’anesthésie et réanimation./n Vice-Président de la Société Marocaine d'Anesthésie, d'Analgésie et de Réanimation (SMAAR). Oujda - Maroc",
                src:"brahim.png"},
                {name:"Pr Kechna Hicham",cv:"Externe du CHU Ibn Sina./n Résident sur concours du CHU Ibn Sina spécialité anesthésie./n Diplôme de spécialité en anesthésie réanimation./n Professeur agrégé anesthésie réanimation./n Chef du pole d’anesthésie, réanimation et urgences à l’hôpital militaire Moulay Ismail Meknès : Depuis janvier 2019.",
                src:"kechna.png"},
                {name:"Pr Kettani Ali",cv:"Professeur de l’enseignement supérieur en anesthésie réanimation./n CHU ibn sina, Rabat./n Chef de service du SAMU01 – Rabat./n Echograhpie clinique aux urgences et en réanimation. Président de Winfocus Maroc.",
                src:"kettani.png"},
                {name:"Pr Levy Bruno",cv:"Professeur des universités-praticien hospitalier. Université de Lorraine./n CHU Nancy Brabois.",
                src:"levy.jpg"},
                {name:"Pr Maazouzi Wajdi",cv:"Ancien président de la SMAR, Ancien directeur de l’UPR d’anesthésie réanimation à la faculté de médecine et de pharmacie de Rabat./n Chef du service d’anesthésie réanimation de l’hôpital des spécialités - CHU Ibn Sina Rabat",
                src:"maazouzi.png"},
                {name:"Pr. Mahmoud mustapha",cv:"Professeur de l’enseignement supérieur en microbiologie Chef de service du laboratoire central d’analyse médicale, chef de l’unité de microbiologie, CHU Hassan II, Fés./n Président du comité de lutte contre les infections nosocomiales (CLIN)  au CHU Hassan II, Fés.",
                src:"mahmoud.jpg"},
                {name:"Dr. Marouan Abdllatif",cv:"Praticien hospitalier en Anesthésie-réanimation au Centre hospitalier André Mignot de Versailles.",
                src:"marouan.png"},
                {name:"Pr Miguil Mohamed",cv:"Ancien chef de service d'anesthésie obstétricale au CHU Ibn Rochd de Casablanca./n Ancien chef de pole d'anesthésie réanimation à l'hôpital Cheikh Khalifa  de Casablanca./n Anesthésiste réanimateur à l'hôpital Cheikh Khalifa./n Enseignant à l'université Mohammed VI des sciences de la santé.",
                src:"miguil.png"},
                {name:"Pr Monnet Xavier",cv:"Professeur des universités-praticien hospitalier. Université Paris-Saclay AP-HP, assistance publique- Hopitaux de Paris./n Le Kremlin-Bicetre, Ile de France, France.",
                src:"monnet.jpg"},
                {name:"Pr. Mouhaoui Mohammed ",cv:"Anesthésiste-réanimateur./n Spécialiste en anesthésie réanimation./n Médecin urgentiste et professeur de l’enseignement supérieur à la faculté de médecine et de pharmacie de Casablanca./n Lauréat de l’université de Paris Descartes./n Expert international en simulation en santé./n Référent pédagogique du Casablanca Advanced Simulation in Health./n Président de la Morocco Sim.",
                src:"mouhaoui.png"},
                {name:"Pr Nassik Hicham",cv:"Professeur de l'enseignement supérieur en anesthésie Réanimation. Enseignant à la faculté de médecine et de pharmacie d'Agadir./n Université Ibn Zohr, Agadir - Maroc.",
                src:"nassik.png"},
                {name:"Pr. Nsiri Afak",cv:"Professeur d’anesthésie réanimation. Service d'Anesthésie Réanimation des urgences chirurgicales, CHU Ibn rochd Casablanca /n Présidente de l’association des médecins anesthésistes réanimateurs de Casablanca (AMARC)",
                src:"nsiri.png"},
                {name:"Pr Qamouss Youssef",cv:"Professeur de l enseignement supérieur en Anesthésie Réanimation. faculté de médecine de marrakech./n Chef de service d anesthésie Réanimation./n Hôpital militaire Avicenne. Marrakech.",
                src:"qamouss.png"},
                {name:"Pr. Rebahi hossam",cv:"Professeur d’anesthésie réanimation Service d’anesthésie réanimation obstétricale, CHU Mohammed VI de Marrakech",
                src:"rebahi.png"},
                {name:"Pr Salmi Said",cv:"Chef de Service d’Anesthésie réanimation hôpital mère-enfant./n CHU Ibn Rochd Casablanca.",
                src:"salmi.png"},
                {name:"Pr Shimi Abdelkarim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, Université Sidi Mohammed Ben Abdellah, Fès.",
                src:"shimi.jpg"},
                {name:"Pr Teboul Jean-Louis",cv:"Professeur de thérapeutique et de médecine des soins intensifs, à l'Université Paris-Saclay en France./n Chef du service de réanimation médicale de l'hôpital universitaire Bicêtre (AP-HP. Université Paris-Saclay), France. Ses recherches portent sur l'hémodynamique des patients en état critique. Il a proposé plusieurs tests  pour évaluer la réactivité aux fluides, tels que la variation de la pression pulsée et l'élévation passive des jambes.",
                src:"teboul.png"},
                {name:"Pr Yahyaoui Ghita ",cv:"Professeur de l’enseignement supérieur en microbiologie Laboratoire central d’analyse médicale, CHU Hassan II, Fés.",
                src:"yahyaoui.jpg"},
                {name:"Pr Touzani Soumaya",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès",
                src:"touzani.jpg"},
                {name:"Pr Abidi Khalid",cv:"PES de Réanimation Médicale./n Président du Club d’Infectiologie de la SMAAR./n Secrétaire Général de Société Marocaine d'Anesthésie, d'Analgésie et de  Réanimation (SMAAR). Membre de la Société de Réanimation Médicale en Langue Française (SRLF) depuis 2004. Reviewer de la revue “Critical Care Medicine”, “BMJ of cases report” et le “Journal of Anesthesiology and Critical Care Medicine”.Membre du “board” de la revue “Intensive Care Medicine”./n Vice Président de la Société de Réanimation de Langue Française",
                  src:"abidi.png"},
                {name:"Pr. Aissaoui Younes",cv:"Chef de service de la réanimation chirurgicale à l’Hôpital militaire Avicenne./n Référent antibiothérapie et membre du Comité de lutte contre l’infection nosocomiale./n Professeur d’anesthésie réanimation à la faculté de médecine de Marrakech./n Membre de la commission recherche à la faculté de médecine de Marrakech./n",
                  src:"aissaoui.png"},
                {name:"Pr Berdai Mohamed Adnane",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Président de l’amicale des médecins anesthésistes réanimateurs de Fès (AMARF)./n Membre du bureau de la société marocaine d’anesthésie, d’analgésie et de réanimation (SMAAR)./n Président du club des anesthésistes réanimateurs pratiquant l’obstétrique (CARPO).",
                  src:"adnane.jpg"},
                {name:"Pr. Belkhadir Zakaria",cv:"Chef de service d'anesthésie réanimation de l'institut national d'oncologie sidi mohamed ben abdellah de Rabat./n Professeur de l'enseignement supérieur de la faculté de médecine et de pharmacie de Rabat./n Président de l'Association des médecins anesthésistes réanimateurs de la région Rabat Salé Kénitra./n",
                src:"belkhadir.png"},
                {name:"Pr. Bouderka Moulay Ahmed",cv:"Réanimateur à la clinique Dar Salam - Casablanca./n Professeur de l’Enseignement Supérieur en Anesthésie-Réanimation, Faculté de Médecine de Casablanca - Président de l’Association Nationale des Cliniques Privées (ANCP) région Grand Casablanca./n Ancien secrétaire général membre de bureau de la Société Marocaine d’Anesthésie Réanimation (SMAR) - Ancien secrétaire général de l’Association des Médecins, Anesthésistes Réanimateurs de Casablanca ( AMARC). Auteur de plusieurs publications dans des revues scientifiques nationales et internationales. ",
                src:"bouderka.png"},
                {name:"Pr Boukatta Brahim",cv:"Dr en Anesthésie-réanimation, CHU Hassan II de Fès Pr à la Faculté de Médecine, Pharmacie et Médecine dentaire de Fès, Université Sidi Mohammed Ben Abdellah",
                src:"boukatta.jpg"},
                {name:"Pr Dendane Tarek",cv:"Professeur de l'enseignement supérieur en Réanimation Médicales. Praticien dans le service de réanimation médicale de l'hôpital Ibn Sina de Rabat./n Membre de l'unité de Formation et de Recherche de Réanimation Médicale et de Médecine expériementale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur et co-auteur d'un grand nombre de projets de recherche à l'échelle nationale et internationale",
                src:"dendane.png"},
                {name:"Pr Derkaoui Ali",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, Pr Derkaoui Ali Université Sidi Mohammed Ben Abdellah, Fès ",
                src:"derkaoui.jpg"},
                {name:"Pr El Adib Ahmed Rhassane",cv:"Chef de Service Anesthésie - Réanimation en Gynécologie - Obstétrique CHU Mohammed VI./n Faculté de Médecine et de Pharmacie - Université Cadi Ayyad , Marrakech. Président de la Société Marocaine de Médecine d'Urgence./n Vice-President of the Moroccan Society of Medical Simulation (Morocco Sim)./n Immediate Past President of the Moroccan Society of Anesthesiology and Intensive Care (SMAR)",
                src:"ghassane.png"},
                {name:"r. El Ahmadi Brahim",cv:"Professeur de l'enseignement supérieur Service d'Anesthésie Réanimation - Institut National d'Oncologie de Rabat./n Faculté de Médecine et de Pharmacie - Université Mohammed V de Rabat",
                src:"brahim_ahmadi.jpg"},
                {name:"Pr El Bouazzaoui Abderrahim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Secrétaire général de l’amicale des médecins anesthésistes  réanimateurs de Fès (AMARF).",
                src:"bouazzaoui.jpeg"},
                {name:"Dr. Hamzaoui Olfa",cv:"Praticien hospitalier temps plein - Service de Réanimation Polyvalente. Hôpital Antoine Béclère, Clamart 92141./n Titulaire de plusieurs diplômes et titres de distinction internationale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur de plusieurs publications dans des revues scientifiques nationales et internationales",
                src:"hamzaoui.png"},
                {name:"Pr. Harandou Mustapha",cv:"Professeur d'anesthésie-réanimation à la faculté de médecine et de  pharmacie, université Sidi Mohammed ben Abdellah à Fès./n Chef du service Réanimation mère et enfant",
                src:"harandou.jpg"},
                {name:"Pr El Harrar Rachid",cv:"Professeur d’enseignement supérieur d’anesthésie- réanimation./n Chef de service de Réanimation des urgences chirurgicales.",
                src:"harrar.png"},
                {name:"Dr Essatara Yassine",cv:"Médecin anesthésiste réanimateur./n Spécialiste en Neuro-réanimation./n Spécialiste en hypnose médicale./n Expert-référent en gestion des voies aériennes difficile.",
                src:"essatara.png"},
                {name:"Pr Ezzouine Hanane",cv:"Professeur de l’enseignement supérieur à la faculté de médecine et de pharmacieUniversité Hassan II - Réanimateur Médical./n Ancien secrétaire général du club d’infectiologie de la SMAAR.",
                src:"ezzouine.png"},
                {name:"Pr Housni Brahim",cv:"PES d’anesthésie et réanimation./n Vice-Président de la Société Marocaine d'Anesthésie, d'Analgésie et de Réanimation (SMAAR). Oujda - Maroc",
                src:"brahim.png"},
                {name:"Pr Kechna Hicham",cv:"Externe du CHU Ibn Sina./n Résident sur concours du CHU Ibn Sina spécialité anesthésie./n Diplôme de spécialité en anesthésie réanimation./n Professeur agrégé anesthésie réanimation./n Chef du pole d’anesthésie, réanimation et urgences à l’hôpital militaire Moulay Ismail Meknès : Depuis janvier 2019.",
                src:"kechna.png"},
                {name:"Pr Kettani Ali",cv:"Professeur de l’enseignement supérieur en anesthésie réanimation./n CHU ibn sina, Rabat./n Chef de service du SAMU01 – Rabat./n Echograhpie clinique aux urgences et en réanimation. Président de Winfocus Maroc.",
                src:"kettani.png"},
                {name:"Pr Levy Bruno",cv:"Professeur des universités-praticien hospitalier. Université de Lorraine./n CHU Nancy Brabois.",
                src:"levy.jpg"},
                {name:"Pr Maazouzi Wajdi",cv:"Ancien président de la SMAR, Ancien directeur de l’UPR d’anesthésie réanimation à la faculté de médecine et de pharmacie de Rabat./n Chef du service d’anesthésie réanimation de l’hôpital des spécialités - CHU Ibn Sina Rabat",
                src:"maazouzi.png"},
                {name:"Pr. Mahmoud mustapha",cv:"Professeur de l’enseignement supérieur en microbiologie Chef de service du laboratoire central d’analyse médicale, chef de l’unité de microbiologie, CHU Hassan II, Fés./n Président du comité de lutte contre les infections nosocomiales (CLIN)  au CHU Hassan II, Fés.",
                src:"mahmoud.jpg"},
                {name:"Dr. Marouan Abdllatif",cv:"Praticien hospitalier en Anesthésie-réanimation au Centre hospitalier André Mignot de Versailles.",
                src:"marouan.png"},
                {name:"Pr Miguil Mohamed",cv:"Ancien chef de service d'anesthésie obstétricale au CHU Ibn Rochd de Casablanca./n Ancien chef de pole d'anesthésie réanimation à l'hôpital Cheikh Khalifa  de Casablanca./n Anesthésiste réanimateur à l'hôpital Cheikh Khalifa./n Enseignant à l'université Mohammed VI des sciences de la santé.",
                src:"miguil.png"},
                {name:"Pr Monnet Xavier",cv:"Professeur des universités-praticien hospitalier. Université Paris-Saclay AP-HP, assistance publique- Hopitaux de Paris./n Le Kremlin-Bicetre, Ile de France, France.",
                src:"monnet.jpg"},
                {name:"Pr. Mouhaoui Mohammed ",cv:"Anesthésiste-réanimateur./n Spécialiste en anesthésie réanimation./n Médecin urgentiste et professeur de l’enseignement supérieur à la faculté de médecine et de pharmacie de Casablanca./n Lauréat de l’université de Paris Descartes./n Expert international en simulation en santé./n Référent pédagogique du Casablanca Advanced Simulation in Health./n Président de la Morocco Sim.",
                src:"mouhaoui.png"},
                {name:"Pr Nassik Hicham",cv:"Professeur de l'enseignement supérieur en anesthésie Réanimation. Enseignant à la faculté de médecine et de pharmacie d'Agadir./n Université Ibn Zohr, Agadir - Maroc.",
                src:"nassik.png"},
                {name:"Pr. Nsiri Afak",cv:"Professeur d’anesthésie réanimation. Service d'Anesthésie Réanimation des urgences chirurgicales, CHU Ibn rochd Casablanca /n Présidente de l’association des médecins anesthésistes réanimateurs de Casablanca (AMARC)",
                src:"nsiri.png"},
                {name:"Pr Qamouss Youssef",cv:"Professeur de l enseignement supérieur en Anesthésie Réanimation. faculté de médecine de marrakech./n Chef de service d anesthésie Réanimation./n Hôpital militaire Avicenne. Marrakech.",
                src:"qamouss.png"},
                {name:"Pr. Rebahi hossam",cv:"Professeur d’anesthésie réanimation Service d’anesthésie réanimation obstétricale, CHU Mohammed VI de Marrakech",
                src:"rebahi.png"},
                {name:"Pr Salmi Said",cv:"Chef de Service d’Anesthésie réanimation hôpital mère-enfant./n CHU Ibn Rochd Casablanca.",
                src:"salmi.png"},
                {name:"Pr Shimi Abdelkarim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, Université Sidi Mohammed Ben Abdellah, Fès.",
                src:"shimi.jpg"},
                {name:"Pr Teboul Jean-Louis",cv:"Professeur de thérapeutique et de médecine des soins intensifs, à l'Université Paris-Saclay en France./n Chef du service de réanimation médicale de l'hôpital universitaire Bicêtre (AP-HP. Université Paris-Saclay), France. Ses recherches portent sur l'hémodynamique des patients en état critique. Il a proposé plusieurs tests  pour évaluer la réactivité aux fluides, tels que la variation de la pression pulsée et l'élévation passive des jambes.",
                src:"teboul.png"},
                {name:"Pr Yahyaoui Ghita ",cv:"Professeur de l’enseignement supérieur en microbiologie Laboratoire central d’analyse médicale, CHU Hassan II, Fés.",
                src:"yahyaoui.jpg"},
                {name:"Pr Touzani Soumaya",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès",
                src:"touzani.jpg"},
                {name:"Pr Abidi Khalid",cv:"PES de Réanimation Médicale./n Président du Club d’Infectiologie de la SMAAR./n Secrétaire Général de Société Marocaine d'Anesthésie, d'Analgésie et de  Réanimation (SMAAR). Membre de la Société de Réanimation Médicale en Langue Française (SRLF) depuis 2004. Reviewer de la revue “Critical Care Medicine”, “BMJ of cases report” et le “Journal of Anesthesiology and Critical Care Medicine”.Membre du “board” de la revue “Intensive Care Medicine”./n Vice Président de la Société de Réanimation de Langue Française",
                  src:"abidi.png"},
                {name:"Pr. Aissaoui Younes",cv:"Chef de service de la réanimation chirurgicale à l’Hôpital militaire Avicenne./n Référent antibiothérapie et membre du Comité de lutte contre l’infection nosocomiale./n Professeur d’anesthésie réanimation à la faculté de médecine de Marrakech./n Membre de la commission recherche à la faculté de médecine de Marrakech./n",
                  src:"aissaoui.png"},
                {name:"Pr Berdai Mohamed Adnane",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Président de l’amicale des médecins anesthésistes réanimateurs de Fès (AMARF)./n Membre du bureau de la société marocaine d’anesthésie, d’analgésie et de réanimation (SMAAR)./n Président du club des anesthésistes réanimateurs pratiquant l’obstétrique (CARPO).",
                  src:"adnane.jpg"},
                {name:"Pr. Belkhadir Zakaria",cv:"Chef de service d'anesthésie réanimation de l'institut national d'oncologie sidi mohamed ben abdellah de Rabat./n Professeur de l'enseignement supérieur de la faculté de médecine et de pharmacie de Rabat./n Président de l'Association des médecins anesthésistes réanimateurs de la région Rabat Salé Kénitra./n",
                src:"belkhadir.png"},
                {name:"Pr. Bouderka Moulay Ahmed",cv:"Réanimateur à la clinique Dar Salam - Casablanca./n Professeur de l’Enseignement Supérieur en Anesthésie-Réanimation, Faculté de Médecine de Casablanca - Président de l’Association Nationale des Cliniques Privées (ANCP) région Grand Casablanca./n Ancien secrétaire général membre de bureau de la Société Marocaine d’Anesthésie Réanimation (SMAR) - Ancien secrétaire général de l’Association des Médecins, Anesthésistes Réanimateurs de Casablanca ( AMARC). Auteur de plusieurs publications dans des revues scientifiques nationales et internationales. ",
                src:"bouderka.png"},
                {name:"Pr Boukatta Brahim",cv:"Dr en Anesthésie-réanimation, CHU Hassan II de Fès Pr à la Faculté de Médecine, Pharmacie et Médecine dentaire de Fès, Université Sidi Mohammed Ben Abdellah",
                src:"boukatta.jpg"},
                {name:"Pr Dendane Tarek",cv:"Professeur de l'enseignement supérieur en Réanimation Médicales. Praticien dans le service de réanimation médicale de l'hôpital Ibn Sina de Rabat./n Membre de l'unité de Formation et de Recherche de Réanimation Médicale et de Médecine expériementale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur et co-auteur d'un grand nombre de projets de recherche à l'échelle nationale et internationale",
                src:"dendane.png"},
                {name:"Pr Derkaoui Ali",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, Pr Derkaoui Ali Université Sidi Mohammed Ben Abdellah, Fès ",
                src:"derkaoui.jpg"},
                {name:"Pr El Adib Ahmed Rhassane",cv:"Chef de Service Anesthésie - Réanimation en Gynécologie - Obstétrique CHU Mohammed VI./n Faculté de Médecine et de Pharmacie - Université Cadi Ayyad , Marrakech. Président de la Société Marocaine de Médecine d'Urgence./n Vice-President of the Moroccan Society of Medical Simulation (Morocco Sim)./n Immediate Past President of the Moroccan Society of Anesthesiology and Intensive Care (SMAR)",
                src:"ghassane.png"},
                {name:"r. El Ahmadi Brahim",cv:"Professeur de l'enseignement supérieur Service d'Anesthésie Réanimation - Institut National d'Oncologie de Rabat./n Faculté de Médecine et de Pharmacie - Université Mohammed V de Rabat",
                src:"brahim_ahmadi.jpg"},
                {name:"Pr El Bouazzaoui Abderrahim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès./n Secrétaire général de l’amicale des médecins anesthésistes  réanimateurs de Fès (AMARF).",
                src:"bouazzaoui.jpeg"},
                {name:"Dr. Hamzaoui Olfa",cv:"Praticien hospitalier temps plein - Service de Réanimation Polyvalente. Hôpital Antoine Béclère, Clamart 92141./n Titulaire de plusieurs diplômes et titres de distinction internationale./n Membre de plusieurs sociétés savantes de renom à l'échelle nationale et internationale./n Auteur de plusieurs publications dans des revues scientifiques nationales et internationales",
                src:"hamzaoui.png"},
                {name:"Pr. Harandou Mustapha",cv:"Professeur d'anesthésie-réanimation à la faculté de médecine et de  pharmacie, université Sidi Mohammed ben Abdellah à Fès./n Chef du service Réanimation mère et enfant",
                src:"harandou.jpg"},
                {name:"Pr El Harrar Rachid",cv:"Professeur d’enseignement supérieur d’anesthésie- réanimation./n Chef de service de Réanimation des urgences chirurgicales.",
                src:"harrar.png"},
                {name:"Dr Essatara Yassine",cv:"Médecin anesthésiste réanimateur./n Spécialiste en Neuro-réanimation./n Spécialiste en hypnose médicale./n Expert-référent en gestion des voies aériennes difficile.",
                src:"essatara.png"},
                {name:"Pr Ezzouine Hanane",cv:"Professeur de l’enseignement supérieur à la faculté de médecine et de pharmacieUniversité Hassan II - Réanimateur Médical./n Ancien secrétaire général du club d’infectiologie de la SMAAR.",
                src:"ezzouine.png"},
                {name:"Pr Housni Brahim",cv:"PES d’anesthésie et réanimation./n Vice-Président de la Société Marocaine d'Anesthésie, d'Analgésie et de Réanimation (SMAAR). Oujda - Maroc",
                src:"brahim.png"},
                {name:"Pr Kechna Hicham",cv:"Externe du CHU Ibn Sina./n Résident sur concours du CHU Ibn Sina spécialité anesthésie./n Diplôme de spécialité en anesthésie réanimation./n Professeur agrégé anesthésie réanimation./n Chef du pole d’anesthésie, réanimation et urgences à l’hôpital militaire Moulay Ismail Meknès : Depuis janvier 2019.",
                src:"kechna.png"},
                {name:"Pr Kettani Ali",cv:"Professeur de l’enseignement supérieur en anesthésie réanimation./n CHU ibn sina, Rabat./n Chef de service du SAMU01 – Rabat./n Echograhpie clinique aux urgences et en réanimation. Président de Winfocus Maroc.",
                src:"kettani.png"},
                {name:"Pr Levy Bruno",cv:"Professeur des universités-praticien hospitalier. Université de Lorraine./n CHU Nancy Brabois.",
                src:"levy.jpg"},
                {name:"Pr Maazouzi Wajdi",cv:"Ancien président de la SMAR, Ancien directeur de l’UPR d’anesthésie réanimation à la faculté de médecine et de pharmacie de Rabat./n Chef du service d’anesthésie réanimation de l’hôpital des spécialités - CHU Ibn Sina Rabat",
                src:"maazouzi.png"},
                {name:"Pr. Mahmoud mustapha",cv:"Professeur de l’enseignement supérieur en microbiologie Chef de service du laboratoire central d’analyse médicale, chef de l’unité de microbiologie, CHU Hassan II, Fés./n Président du comité de lutte contre les infections nosocomiales (CLIN)  au CHU Hassan II, Fés.",
                src:"mahmoud.jpg"},
                {name:"Dr. Marouan Abdllatif",cv:"Praticien hospitalier en Anesthésie-réanimation au Centre hospitalier André Mignot de Versailles.",
                src:"marouan.png"},
                {name:"Pr Miguil Mohamed",cv:"Ancien chef de service d'anesthésie obstétricale au CHU Ibn Rochd de Casablanca./n Ancien chef de pole d'anesthésie réanimation à l'hôpital Cheikh Khalifa  de Casablanca./n Anesthésiste réanimateur à l'hôpital Cheikh Khalifa./n Enseignant à l'université Mohammed VI des sciences de la santé.",
                src:"miguil.png"},
                {name:"Pr Monnet Xavier",cv:"Professeur des universités-praticien hospitalier. Université Paris-Saclay AP-HP, assistance publique- Hopitaux de Paris./n Le Kremlin-Bicetre, Ile de France, France.",
                src:"monnet.jpg"},
                {name:"Pr. Mouhaoui Mohammed ",cv:"Anesthésiste-réanimateur./n Spécialiste en anesthésie réanimation./n Médecin urgentiste et professeur de l’enseignement supérieur à la faculté de médecine et de pharmacie de Casablanca./n Lauréat de l’université de Paris Descartes./n Expert international en simulation en santé./n Référent pédagogique du Casablanca Advanced Simulation in Health./n Président de la Morocco Sim.",
                src:"mouhaoui.png"},
                {name:"Pr Nassik Hicham",cv:"Professeur de l'enseignement supérieur en anesthésie Réanimation. Enseignant à la faculté de médecine et de pharmacie d'Agadir./n Université Ibn Zohr, Agadir - Maroc.",
                src:"nassik.png"},
                {name:"Pr. Nsiri Afak",cv:"Professeur d’anesthésie réanimation. Service d'Anesthésie Réanimation des urgences chirurgicales, CHU Ibn rochd Casablanca /n Présidente de l’association des médecins anesthésistes réanimateurs de Casablanca (AMARC)",
                src:"nsiri.png"},
                {name:"Pr Qamouss Youssef",cv:"Professeur de l enseignement supérieur en Anesthésie Réanimation. faculté de médecine de marrakech./n Chef de service d anesthésie Réanimation./n Hôpital militaire Avicenne. Marrakech.",
                src:"qamouss.png"},
                {name:"Pr. Rebahi hossam",cv:"Professeur d’anesthésie réanimation Service d’anesthésie réanimation obstétricale, CHU Mohammed VI de Marrakech",
                src:"rebahi.png"},
                {name:"Pr Salmi Said",cv:"Chef de Service d’Anesthésie réanimation hôpital mère-enfant./n CHU Ibn Rochd Casablanca.",
                src:"salmi.png"},
                {name:"Pr Shimi Abdelkarim",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, Université Sidi Mohammed Ben Abdellah, Fès.",
                src:"shimi.jpg"},
                {name:"Pr Teboul Jean-Louis",cv:"Professeur de thérapeutique et de médecine des soins intensifs, à l'Université Paris-Saclay en France./n Chef du service de réanimation médicale de l'hôpital universitaire Bicêtre (AP-HP. Université Paris-Saclay), France. Ses recherches portent sur l'hémodynamique des patients en état critique. Il a proposé plusieurs tests  pour évaluer la réactivité aux fluides, tels que la variation de la pression pulsée et l'élévation passive des jambes.",
                src:"teboul.png"},
                {name:"Pr Yahyaoui Ghita ",cv:"Professeur de l’enseignement supérieur en microbiologie Laboratoire central d’analyse médicale, CHU Hassan II, Fés.",
                src:"yahyaoui.jpg"},
                {name:"Pr Touzani Soumaya",cv:"Professeur d’anesthésie réanimation au CHU Hassan II à Fès et à  la faculté de médecine, de pharmacie et de médecine dentaire, université Sidi Mohammed Ben Abdellah, Fès",
                src:"touzani.jpg"},
               


                
                
            ],
              responsive: [
                  [200, 1],[767, 2],[1000, 5]
              ],
              animation:true
          }
      },
      props: ['slideshow'],
      components: {
          Carousel,
          Slide
      },
      methods: {
        hideHand(){
          this.animation = false
        }
      },
  
      mounted() {
        
      
    },
  }
  </script>
  <style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .v-card--reveal-first {
    align-items: center;
    top: 120px;
    justify-content: center;
    position: absolute;
    width: 100%;
    font-size:1.2em
  }
  .cvtext{
      display: block;
      font-size:0.9em;
      white-space: pre-line
  }
  .speakerName{
    height: 60%;
    text-align:center
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    .cvtext{
      display: block;
      font-size:1.1vw;
    }
    .v-card--reveal-first {
      font-size:1em
    }
    .speakerName{
      height: 40%;
    }
  }
  @media (min-width: 768px) and (max-width: 999px) {
    .cvtext{
      display: block;
      font-size:1.5vw;
    }
    .v-card--reveal-first {
      font-size:1.3em
    }
  }
  @media (max-width: 767px) {
    .cvtext{
      display: block;
      font-size:2.9vw;
    }
    .v-card--reveal-first {
      font-size:1.5em
    }
    .speakerName{
      height: 80%;
    }
  }
 
  .carros{
    position: relative;
  }
  @-webkit-keyframes pulsate {
    from {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
  
  .pulsate {
    position: absolute;
    top: 50%;
    left: 12.5%;
    animation-name: pulsate;
    animation: pulsate 5s infinite;
    animation-duration: 5s;
  }
  
  @media (max-width: 480px) {
    .pulsate {
        left: 45%;
      }
  }
  
  
  
  
  </style>